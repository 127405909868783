import { Stimulus, Controller } from '#/layouts/js/stimulus'

const snippetCookie = document.cookie.match(/showSnippet=(0|1)/)
let showSnippet = snippetCookie && snippetCookie[1] == '1'

Stimulus.register('snippet', class extends Controller {
  connect() {
    this.element.checked = showSnippet
  }

  change(e) {
    showSnippet = e.target.checked
    document.cookie = `showSnippet=${showSnippet ? 1 : 0}; path=/; samesite=strict; max-age=31536000`
  }
})
