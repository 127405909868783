import { Stimulus, Controller } from '#/layouts/js/stimulus'
import { visit } from '@hotwired/turbo'
import { tabletMode } from './utils'

const selectResult = function(el) {
  if (!el) return

  document.querySelectorAll('#search-results tr').forEach(el => el.classList.remove('table-active'))
  el.classList.add('table-active')
  el.scrollIntoView({ block: 'center', behavior: 'smooth' })
  document.getElementById('search-result-document').src = el.getAttribute('data-document')
}

Stimulus.register('results', class extends Controller {
  connect() {
    if (!document.querySelector('.table-active'))
      selectResult(document.querySelector('#search-results tr[data-document]'))
  }

  select(e) {
    const tr = e.target.closest('tr')
    selectResult(tr)
    if (tabletMode()) {
      visit(tr.getAttribute('data-document'))
    }
  }
})

export { selectResult }
