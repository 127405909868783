import { Stimulus, Controller } from '#/layouts/js/stimulus'
import { tabletMode } from './utils'

let resizeTimeout

const resizeObserver = new ResizeObserver(() => {
  const container = document.querySelector('[data-controller="container"]')
  resizeTimeout && clearTimeout(resizeTimeout)

  // Debounce resize events
  setTimeout(() => {
    if (tabletMode()) {
      for (const el of container.children) {
        el.style.maxHeight = null
        el.classList.remove('overflow-y-scroll')
      }
    } else {
      if (window.scrollY > 0) window.scrollTo(0, 0)

      const maxHeight = `${window.innerHeight - container.getBoundingClientRect().top - 5}px`
      for (const el of container.children) {
        el.style.maxHeight = maxHeight
        el.classList.add('overflow-y-scroll')
      }
    }

    const activeResult = document.querySelector('#search-results tr.table-active')
    activeResult && activeResult.scrollIntoView({ block: 'center' })
  }, 100)
})

Stimulus.register('container', class extends Controller {
  connect() {
    resizeObserver.observe(document.body)
  }

  disconnect() {
    resizeObserver.disconnect()
  }
})
